<template>
  <div>
    <a-select v-model="index" size="large" style="min-width: 450px; margin-bottom: 20px;">
      <a-select-option v-for="(option, i) in dropdownOptions" :key="i" :value="i">{{ option }}</a-select-option>
    </a-select>
    <div class="video-media-wrapper">
        <video-media :max-height="600" :media="selectedMedia" :key="index"></video-media> 
    </div>
  </div>
</template>

<script>
import VideoMedia from "../../../../../views/Guidance/Show/TopicTabs/VideoMedia.vue";
const _ = require('lodash');
export default {
  props: ["tenantId", "orgId", "helpVideo"],
  components: {VideoMedia},
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    media() {
      return this.helpVideo["$v"]["media"];
    },

    selectedMedia() {
      return [this.media[this.index]];
    },

    dropdownOptions() {
      let options = [];
      _.each(this.media, m => {
        let description = '';

        if (m['$v']['available']) {
          description = description + 'Available';
        } else {
          description = description + 'Unavailable';
        }

        if (m['$v']['orientation'] == 1) {
          description = description + ' | Portrait';
        } else {
          description = description + ' | Landscape';
        }

        if (m['$v']['contentType'] == 'text/html') {
          description = description + ' | Web embedded'
        } else {
          description = description + ' | ' + (m['$v']['byteSize'] ? m['$v']['byteSize'] : 'unknown') + ' bytes'
        }

        options.push(description);
      });
      return options;
    }
  },
};
</script>

<style scoped lang="scss">
</style>