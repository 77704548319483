<template>
  <div class="view-admin-guidance-help-video">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined">
      <h1 class="page-title">
        Help Video: {{ helpVideo["$v"]["displayName"] }}
      </h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          v-if="canEdit"
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Tabs -->
    <div v-if="!isLoadingCombined">
      <content-link-tabs
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :parent-id="helpVideo['$v'].id"
        parent-type="Help Video"
        :content-links="contentLinks"
        :is-loading="isLoadingContentLinks"
        :types="['articles']"
        :children-of="['articles']"
        @refresh="loadContentLinks"
        :show-details-tab="true"
        :can-edit="canEdit"
      >
        <a-alert
          type="error"
          message="No media uploaded"
          v-if="!helpVideo['$v']['media']"
        />

        <help-video-media-viewer
          v-if="helpVideo['$v']['media']"
          :tenant-id="tenantId"
          :org-id="selectedOrganisation.id"
          :help-video="helpVideo"
        >
        </help-video-media-viewer>
      </content-link-tabs>
    </div>
    <!-- / Tabs -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import helpVideos from "../../../../api/guidance/help-videos";
import contentLinks from "../../../../api/guidance/content-links";
import ContentLinkTabs from "../../../../components/Admin/Guidance/ContentLinkTabs.vue";
import HelpVideoMediaViewer from "./Show/HelpVideoMediaViewer.vue";

export default {
  components: { LoadingScreen, ContentLinkTabs, HelpVideoMediaViewer },

  data() {
    return {
      isLoadingHelpVideo: true,
      helpVideo: null,

      isDeleting: false,

      isLoadingParentContentLinks: false,
      parentContentLinks: [],

      isLoadingChildrenContentLinks: false,
      childrenContentLinks: [],
    };
  },

  created() {
    // if (!this.article) {
    //   this.$message.info("Article not found");
    //   this.$router.push("/admin/guidance/articles");
    // }
    this.loadHelpVideo();
    this.loadContentLinks();
  },

  methods: {
    ...mapActions("adminHelpVideos", {
      loadHelpVideos: "loadHelpVideos",
    }),

    goBack() {
       if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/help-videos");
      }
    },

    edit() {
      this.$router.push(
        "/admin/guidance/help-videos/" + this.helpVideoId + "/edit"
      );
    },

    loadHelpVideo() {
      let vm = this;
      vm.isLoadingHelpVideo = true;
      helpVideos
        .getHelpVideo(this.tenantId, this.helpVideoId)
        .then((r) => {
          vm.isLoadingHelpVideo = false;
          vm.helpVideo = r.data;
        })
        .catch((e) => {
          this.loadHelpVideos()
            .then((allVideos) => {
              let helpVideo = _.find(allVideos, (hv) => {
                return hv["$v"]["id"] == vm.helpVideoId;
              });
              if (helpVideo) {
                vm.helpVideo = helpVideo;
                vm.isLoadingHelpVideo = false;
              } else {
                console.log(e);
                this.$message.info("Help video not found");
                this.$router.push("/admin/guidance/help-videos");
              }
            })
            .catch((e) => {
              console.log(e);
              this.$message.info("Help video not found");
              this.$router.push("/admin/guidance/help-videos");
            });
        });
    },

    loadContentLinks() {
      this.loadChildrenContentLinks();
      this.loadParentContentLinks();
    },

    loadChildrenContentLinks() {
      let vm = this;
      vm.isLoadingChildrenContentLinks = true;
      contentLinks
        .getContentLinksForOrgByParentEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.helpVideoId
        )
        .then((r) => {
          vm.isLoadingChildrenContentLinks = false;
          vm.childrenContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChildrenContentLinks = false;
        });
    },

    loadParentContentLinks() {
      let vm = this;
      vm.isLoadingParentContentLinks = true;
      contentLinks
        .getContentLinksForOrgByChildEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.helpVideoId
        )
        .then((r) => {
          vm.isLoadingParentContentLinks = false;
          vm.parentContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingParentContentLinks = false;
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      helpVideos
        .deleteHelpVideo(vm.tenantId, vm.helpVideoId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Help video deleted successfully");
          vm.$router.push("/admin/guidance/help-videos");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting help video");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminHelpVideos", {
      helpVideos: "helpVideos",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor",
    }),

    isLoadingContentLinks() {
      return (
        this.isLoadingParentContentLinks || this.isLoadingChildrenContentLinks
      );
    },

    contentLinks() {
      return _.unionBy(
        this.parentContentLinks,
        this.childrenContentLinks,
        "$v.id"
      );
    },

    helpVideoId() {
      return this.$route.params.id;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingHelpVideo;
    },

    canEdit() {
      if (!this.helpVideo) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(
        this.helpVideo["$v"]["ownerId"]
      );
    },
  },
};
</script>

<style lang="scss">
.view-admin-guidance-help-video {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }
}
</style>